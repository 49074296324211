import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ProjectCardListing from '../components/ProjectCardListing';
import ProjectTypeSelector from '../components/ProjectTypeSelector';
import ModularBlocks from '../components/ModularBlocks';

const ProjectsPage = () => {
  const {
    datoCmsProjectsArchive: {
      seoMetaTags,
      title,
      bannerText,
      bannerImage,
      modularBlocks,
    },
    allDatoCmsProject: { nodes },
  } = useStaticQuery(graphql`
    query ProjectsPageQuery {
      datoCmsProjectsArchive {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerText
        bannerImage {
          ...SubpageBannerImageFragment
        }
        modularBlocks {
          ...ContentModularBlockFragment
          ...ImageContentModularBlockFragment
          ...VideoContentModularBlockFragment
        }
      }
      allDatoCmsProject(sort: { fields: position }) {
        nodes {
          ...ProjectCardFragment
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner heading={title} text={bannerText} image={bannerImage} />
        <ProjectCardListing items={nodes} />
        <ProjectTypeSelector fullWidth={true} />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export default ProjectsPage;
